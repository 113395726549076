import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'
// import { Button } from './Button.js';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true)

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if(window.innerWidth <= 960) {
      setButton(false)
    } else {
      setButton(true)
    }
  };

  useEffect(() => {
    showButton()
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            sidwall.se
          </Link>
          {/* <div className="menu-icon" onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
          </div> */}
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            {/* <li className='nav-item'>
                <Link 
                  to='/' 
                  className='nav-links' 
                  onClick={closeMobileMenu}
                >
                    Hem
                </Link>
            </li>
            <li className='nav-item'>
                <Link 
                  to='/teknikguide' 
                  className='nav-links' 
                  onClick={closeMobileMenu}
                >
                    Teknikguide
                </Link>
            </li>
            <li className='nav-item'>
                <Link 
                  to='/quiz' 
                  className='nav-links' 
                  onClick={closeMobileMenu}
                >
                    Quiz
                </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/cv'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                CV
                </Link>
            </li>
            <li className=''>
                <Link 
                  to='/login'
                  className='nav-links-mobile' 
                  onClick={closeMobileMenu}
                >
                    Logga in
                </Link>
            </li> */}
          </ul>
          {/* {button && <Button buttonStyle='btn--outline'>Logga in</Button>} */}
        </div>
      </nav>
    </>
  )
}

export default Navbar;
