import React, { Component } from 'react'

export class QuizForm extends Component {
  render() {
    return (
      <div className="form-container">
        <div className="form-paragraph">
          <h1>TODO: Build a quiz form</h1>
        </div>
      </div>
    )
  }
}

export default QuizForm
